export function calcNivel(probrabilidade, impacto) {
  const matriz = [
    [
      "Risco Baixo",
      "Risco Baixo",
      "Risco Moderado",
      "Risco Elevado",
      "Risco Elevado",
    ],
    [
      "Risco Baixo",
      "Risco Baixo",
      "Risco Moderado",
      "Risco Elevado",
      "Risco Extremo",
    ],
    [
      "Risco Baixo",
      "Risco Moderado",
      "Risco Elevado",
      "Risco Extremo",
      "Risco Extremo",
    ],
    [
      "Risco Moderado",
      "Risco Elevado",
      "Risco Elevado",
      "Risco Extremo",
      "Risco Extremo",
    ],
    [
      "Risco Elevado",
      "Risco Elevado",
      "Risco Extremo",
      "Risco Extremo",
      "Risco Extremo",
    ],
  ];
  return matriz[probrabilidade][impacto];
}
