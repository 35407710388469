import { useEffect, useReducer, useState } from "react";
import AuthContext from "./contexts/auth";
import authReducer, { authReducerInitialValue } from "./reducers/auth";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import "./App.css";
import {
  SideNavigateContext,
  DataUserContext,
  CodeDataContext,
} from "./contexts/context";
import AppRoutes from "./routes/Routes";
import WithAxios from "./services/WhithAxios";
import { Decode } from "./services/Decode";
import { ReactSession } from "react-client-session";
function App() {
  const [state, dispatch] = useReducer(authReducer, authReducerInitialValue());

  const [userData, setUserData] = useState({});
  const [OpenSideNavigate, setOpenSideNavigate] = useState(true);
  const [codeData, setCodeData] = useState({});
  ReactSession.setStoreType("sessionStorage");
  async function loadStorage() {
    const token = ReactSession.get("token");

    if (!!token) {
      dispatch({
        type: "LOADED",
        payload: {
          token: token,
        },
      });
    }
  }
  useEffect(() => {
    loadStorage();
  }, [state.isAuthenticated]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <AuthContext.Provider value={{ state, dispatch }}>
        <WithAxios>
          <DataUserContext.Provider value={{ userData, setUserData }}>
            <CodeDataContext.Provider value={{ codeData, setCodeData }}>
              <SideNavigateContext.Provider
                value={{ OpenSideNavigate, setOpenSideNavigate }}
              >
                <AppRoutes />
              </SideNavigateContext.Provider>
            </CodeDataContext.Provider>
          </DataUserContext.Provider>
        </WithAxios>
      </AuthContext.Provider>
    </LocalizationProvider>
  );
}

export default App;
