import Placeholder from "react-bootstrap/Placeholder";
export function GerarDocumento() {
  return (
    <div style={{ width: "80%" }} className="">
      <div className=" flex-column ">
        <Placeholder animation="glow" style={{}}>
          <Placeholder
            style={{ width: "100%", height: "100px", marginTop: "20px" }}
            size="lg"
            bg="secondary"
          />
        </Placeholder>
        <Placeholder animation="glow" style={{}}>
          <Placeholder
            style={{ width: "100%", height: "100px", marginTop: "20px" }}
            size="lg"
            bg="secondary"
          />
        </Placeholder>
        <Placeholder animation="glow" style={{}}>
          <Placeholder
            style={{ width: "100%", height: "100px", marginTop: "20px" }}
            size="lg"
            bg="secondary"
          />
        </Placeholder>
        <Placeholder animation="glow" style={{}}>
          <Placeholder
            style={{ width: "100%", height: "100px", marginTop: "20px" }}
            size="lg"
            bg="secondary"
          />
        </Placeholder>
      </div>

      <div className="row justify-content-end">
        <div className="col-md ">
          <Placeholder animation="glow" style={{}}>
            <Placeholder
              style={{ width: "20%", height: "40px", marginTop: "30px" }}
              size="lg"
              bg="secondary"
            />
          </Placeholder>
        </div>
      </div>
    </div>
  );
}
