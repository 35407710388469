import { useNavigate } from "react-router-dom";
import { ReactSession } from "react-client-session";
const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      ReactSession.set("token", action.payload.token);

      return {
        ...state,

        isAuthenticated: true,
        token: action.payload,
      };
    case "LOGOUT":
      ReactSession.remove("token");
      return {
        ...state,
        isAuthenticated: false,
      };
    case "LOADED":
      return {
        isAuthenticated: true,
        token: action.payload.token,
      };
    default:
      return state;
  }
};

export const authReducerInitialValue = () => {
  const Token = ReactSession.get("token");

  if (Token) {
    return {
      isAuthenticated: true,
      token: Token,
    };
  } else {
    return {
      isAuthenticated: false,
      token: null,
    };
  }
};
export default authReducer;
