import { Link } from "react-router-dom";

import "./MenuRetractable.scss";

const MenuRetractable = (props) => {
  function CloseMenu() {
    props.setOpenSideNavigate(true);
  }

  return (
    <aside className="mt-4 aside_retractable">
      <div className="menu_retractable">
        <div className="barra_superior_retractable">
          <button className="btn btn-sm" onClick={CloseMenu}>
            <i className="fa-solid fa-angles-right"></i>
          </button>
        </div>
        <div>
          <nav className="scroll_menu_retractable_risco">
            <ul>
              <li className={props.ativo === 1 ? "ativo" : ""}>
                {props.code ? (
                  <Link
                    className="link"
                    to={`/informacoes-basicas?code=${props.code}`}
                  >
                    1
                  </Link>
                ) : (
                  <Link className="link" to="/informacoes-basicas">
                    1
                  </Link>
                )}
              </li>
              <li className={props.ativo === 2 ? "ativo" : ""}>
                {props.code ? (
                  <Link
                    className="link"
                    to={`/risco-da-contratacao?code=${props.code}`}
                  >
                    2
                  </Link>
                ) : (
                  <Link className="link" to="/risco-da-contratacao">
                    2
                  </Link>
                )}
              </li>
              <li className={props.ativo === 3 ? "ativo" : ""}>
                {props.code ? (
                  <Link className="link" to={`/responsavel?code=${props.code}`}>
                    3
                  </Link>
                ) : (
                  <Link className="link" to="/responsavel">
                    3
                  </Link>
                )}
              </li>
              <li className={props.ativo === 4 ? "ativo" : ""}>
                {props.code ? (
                  <Link
                    className="link"
                    to={`/descricao-de-requisito-da-contatacao?code=${props.code}`}
                  >
                    4
                  </Link>
                ) : (
                  <Link
                    className="link"
                    to="/descricao-de-requisito-da-contatacao"
                  >
                    4
                  </Link>
                )}
              </li>
              <li className={props.ativo === 5 ? "ativo" : ""}>
                {props.code ? (
                  <Link
                    className="link"
                    to={`/gerar-documentos?code=${props.code}`}
                  >
                    5
                  </Link>
                ) : (
                  <Link className="link" to="/gerar-documentos">
                    5
                  </Link>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </aside>
  );
};

export default MenuRetractable;
