import styles from "./NumeroProcesso.scss";

import Button from "react-bootstrap/Button";
// import Form from 'react-bootstrap/Form';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import Navbar from "./../../../components/Navbar/Navbar";

import NavegacaoLateral from "./../../../components/NavegacaoLateral/NavegacaoLateral";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../../components/Footer/Footer";

import { useContext, useEffect, useState } from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { FormGroup } from "react-bootstrap";
import * as yup from "yup";
import api from "../../../services/api";
import AuthContext from "../../../contexts/auth";
import { CodeDataContext } from "../../../contexts/context";
import { NumProcessoSkeleton } from "../../../components/skeletons/NumProcessoSkeleton";
import { ModalError } from "../../../components/ModalError/ModalError";

const NumeroProcesso = () => {
  const [valueRequester, setValueRequester] = useState([]);
  const [NumDocumento, setNumDocumento] = useState("");
  const [NumProcessInterno, setNumProcessInterno] = useState("");
  const [load, setLoad] = useState(false);
  const [code, setCode] = useState("");
  const { state } = useContext(AuthContext);
  const { codeData, setCodeData } = useContext(CodeDataContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const schema = yup.object().shape({
    NumProcessInterno: yup.string().typeError("O Valor deve ser um número"),

    NumDocumento: yup
      .string()
      .typeError("O Valor deve ser um número")
      .required("O número do documento é obrigatório"),
  });

  async function getDataRisco(code) {
    try {
      api
        .get(`/api/v1.0/gestoes-riscos/${code}`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then((res) => {
          setValueRequester(res?.data);
          setNumDocumento(res.data?.NumeroDocumento);
          setNumProcessInterno(res.data?.NumeroProcessoInterno);
          setLoad(true);
        });
    } catch (error) {
      ModalError(error);
    }
  }

  async function LoadData() {
    const code = searchParams.get("code");
    if (code !== null) {
      setCode(code);
      getDataRisco(code);
    } else {
      setLoad(true);
    }
  }
  useEffect(() => {
    LoadData();
  }, []);

  async function handleSubmit(e) {
    try {
      if (!code) {
        const response = await api.post(
          "/api/v1.0/gestoes-riscos",
          {
            NumeroDocumento: e.NumDocumento,
            NumeroProcessoInterno: e.NumProcessInterno
              ? e.NumProcessInterno
              : null,
          },
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        );

        setCodeData({
          CodigoGestaoRisco: response.data.Codigo,
        });
        setCode(response.data.Codigo);
        navigate(`/risco-da-contratacao?code=${response.data.Codigo}`);
      } else {
        await api
          .put(
            "/api/v1.0/gestoes-riscos",
            {
              Codigo: code,
              NumeroDocumento: e.NumDocumento,
              NumeroProcessoInterno: e.NumProcessInterno
                ? e.NumProcessInterno
                : null,
            },
            {
              headers: {
                Authorization: `Bearer ${state.token}`,
              },
            }
          )
          .then(() => {
            navigate(`/risco-da-contratacao?code=${code}`);
          });
      }
    } catch (error) {
      ModalError(error);
    }
  }
  return (
    <>
      <Navbar titulo="Gestão de Risco" />

      <main>
        <div className="container">
          <div className="row">
            <NavegacaoLateral ativo={1} code={code} />

            <div className="col-sm-9 mt-4">
              <h2>Informações básicas</h2>

              <div className="container">
                {load ? (
                  <Formik
                    initialValues={{
                      NumDocumento: NumDocumento,
                      NumProcessInterno: NumProcessInterno,
                    }}
                    validationSchema={schema}
                    validateOnBlur={false}
                    onSubmit={handleSubmit}
                  >
                    {({ setFieldValue }) => (
                      <Form className="d-flex flex-column justify-content-end  ">
                        <div className="d-flex justify-content-end align-items-end">
                          <div className="col-md-6 ">
                            <FormGroup className="mb-3 p-1 formGroup">
                              <label className="">
                                <span className="d-flex flex-row align-items-center">
                                  Número do documento - Gestão de Riscos
                                </span>
                              </label>
                              <Field
                                type="text"
                                className="form-control"
                                name="NumDocumento"
                              />
                              <ErrorMessage component="p" name="NumDocumento" />
                            </FormGroup>
                          </div>

                          <div className="d-flex flex-column w-50 justify-content-end ">
                            <FormGroup className="mb-3 p-1 formGroup">
                              <label>
                                <span className="d-flex flex-row align-items-center">
                                  Número do processo interno
                                </span>
                              </label>
                              <Field
                                type="text"
                                className="form-control"
                                name="NumProcessInterno"
                              />
                              <ErrorMessage
                                component="p"
                                name="NumProcessInterno"
                              />
                            </FormGroup>
                          </div>
                        </div>
                        <div>
                          <Button
                            className="btn btn-primary button "
                            type="submit"
                          >
                            Salvar e Continuar
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                ) : (
                  <NumProcessoSkeleton />
                )}
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* Rodape */}
      <Footer />
    </>
  );
};

export default NumeroProcesso;
