import styled from 'styled-components'
import Alert from '@mui/material/Alert';

export const AlertMessage =styled(Alert)`
   
    
    ${props =>{
        switch(props.severity){
            case "error":
            return`
            color:#F7412D!important;
            background-color: rgba(247,65,45,0.15); !important;
            font-size: 16 !important;
            Padding: 11px;
            `;
            default:
                return 'color:black';
        }
    }}

`;