import styles from "./RiscoContratacao.scss";
import { HiPencil } from "react-icons/hi";
import { MdCancel } from "react-icons/md";
import { BsExclamationLg, BsFillExclamationTriangleFill } from "react-icons/bs";
import Form from "react-bootstrap/Form";
import Tooltip from "react-bootstrap/Tooltip";
import React, { useContext, useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import Navbar from "../../../components/Navbar/Navbar";
import * as yup from "yup";
import NavegacaoLateral from "../../../components/NavegacaoLateral/NavegacaoLateral";
import CKeditorEtp from "../../../components/CKeditor/CKeditorEtp";
import Footer from "../../../components/Footer/Footer";
import { ModalAcao } from "../../../components/ModalAcao/ModalAcao";
import { calcNivel } from "../../../functions/calcNivel";
import { ErrorMessage, Field, Formik } from "formik";
import { removerTags } from "../../../functions/removeHtml";
import { CodeDataContext, DataUserContext } from "../../../contexts/context";
import api from "../../../services/api";
import AuthContext from "../../../contexts/auth";
import { RiscoContratacaoSkeleton } from "../../../components/skeletons/RiscoContratacaoSkeleton";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Importe o arquivo CSS do editor de texto
import { ModalError } from "../../../components/ModalError/ModalError";

const RiscoContratacao = () => {
  const { codeData, setCodeData } = useContext(CodeDataContext);
  const { userData, setUserData } = useContext(DataUserContext);
  const { state } = useContext(AuthContext);
  const [show, setShow] = useState(false);

  const [showEtapa, setShowEtapa] = useState(false);
  const handleCloseEtapa = () => setShowEtapa(false);
  const handleShowEtapa = () => setShowEtapa(true);
  const [code, setCode] = useState("");
  const [dataEditor, setDataEditor] = useState(" ");
  const [contentCategory, setContentCategory] = useState(" ");

  const [nivel, setNivel] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [request, setRequest] = useState({});
  const [faseSelect, setFaseSelect] = useState([]);
  const [alocado, setAlocado] = useState([]);
  const [probSelect, setProbSelect] = useState([]);
  const [impactoSelect, setImpactoSelect] = useState([]);
  const [itemContratacao, setItemContratacao] = useState("");
  const [load, setLoad] = useState(false);

  const [currentAction, setCurrentAction] = useState({});
  const [valueRequester, setValueRequester] = useState([]);
  const [newInput, setNewInput] = useState(true);
  const [updateValue, setUpdateValue] = useState(false);
  const [currentItemUpdate, setCurrentItemUpdate] = useState("");

  const [indexActionNull, setIndexActionNull] = useState([]);
  const MySwal = withReactContent(Swal);
  const [listRisco, setListRisco] = useState([]);
  const [chamarContActionsRisco, setChamarContActionsRisco] = useState(false);

  const schema = yup.object().shape({
    text: yup.string().required("O Campo é obrigatório"),
    faseSelect: yup
      .object()
      .typeError("A fase é obrigatória")
      .required("A fase é obrigatória"),
    alocado: yup
      .object()
      .typeError("A fase é obrigatória")
      .required("A fase é obrigatória"),
    probSelect: yup
      .object()
      .typeError("A probabilidade é obrigatória")
      .required("A probabilidade é obrigatória"),
    impactoSelect: yup
      .object()
      .typeError("O Impacto é obrigatório")
      .required("O Impacto é obrigatório"),
    itemContratacao: yup.string(),
  });

  function ContActionsRisco() {
    // contador de acao
    let temprisco = [...listRisco];
    let temp = [];

    try {
      listRisco.map(async (item, index) => {
        if (item.CodigoRiscoContratacao) {
          await api
            .get(
              `/api/v1.0/acoes?codigoRiscoContratacao=${item.CodigoRiscoContratacao}`,
              {
                headers: {
                  Authorization: `Bearer ${state.token}`,
                },
              }
            )
            .then((res) => {
              if (res.data) {
                let filterCont = res.data.filter(
                  (item) => item.Tipo === "Contingencia"
                );
                let filterPrev = res.data.filter(
                  (item) => item.Tipo === "Preventiva"
                );

                temprisco[index].countPrev =
                  filterPrev.length > 0 ? filterPrev.length : null;
                temprisco[index].countConti =
                  filterCont.length > 0 ? filterCont.length : null;
              } else {
              }
            });
        }
        console.log(temprisco);
        setListRisco(temprisco);
      });
    } catch (error) {
      console.log("Error: " + error);
    }
  }

  const handleClose = () => {
    setShow(false);
    setCurrentAction({});
    ContActionsRisco();
  };

  useEffect(() => {
    if (chamarContActionsRisco) {
      ContActionsRisco();
      setChamarContActionsRisco(false); // Redefine o estado para evitar loops
    }
  }, [chamarContActionsRisco]);

  const handleShow = () => setShow(true);

  function RemoveItem(key) {
    MySwal.fire({
      title: "Tem certeza?",
      text: "Você não será capaz de reverter isso!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, exclua!",
    }).then((result) => {
      if (result.isConfirmed) {
        removeRiscoApi(listRisco[key].CodigoRiscoContratacao);
        const items = [...listRisco];
        items.splice(key, 1);
        setListRisco(items);

        MySwal.fire("Excluído!", "Seu risco foi deletado.", "success");
      }
    });
  }

  async function removeRiscoApi(code) {
    try {
      await api.delete(`/api/v1.0/riscos-contratacoes/${code}`, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function ValidateGetAction() {
    let tempReturn = "";
    let temp = [];
    try {
      listRisco.map(async (item, index) => {
        await api
          .get(
            `/api/v1.0/acoes?codigoRiscoContratacao=${item.CodigoRiscoContratacao}`,
            {
              headers: {
                Authorization: `Bearer ${state.token}`,
              },
            }
          )
          .then((res) => {
            if (res.data) {
              tempReturn = false;
            } else {
              tempReturn = true;
            }
            if (tempReturn) {
              temp.push(index + 1);
            }
          });

        setIndexActionNull(temp);
      });
    } catch (error) {
      console.log("Error: " + error);
    }
  }

  async function getDataRisco(code) {
    try {
      api
        .get(`/api/v1.0/riscos-contratacoes?codigoGestaoRisco=${code}`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then((res) => {
          if (res.data) {
            const filteredData = res.data.filter(
              (item) => item.Status !== "Deletado"
            );

            let dataRes = filteredData.map((item) => ({
              fase: item.Fase,
              fase2: item.Alocado,
              probabilidade: item.Probabilidade,
              impacto: item.Impacto,
              nivel: item.Nivel,
              describeRisco: item.Descricao,
              item: item.Item,
              CodigoRiscoContratacao: item.Codigo,
              acao: false,
            }));

            setListRisco(dataRes);
            setChamarContActionsRisco(true);
          }

          setLoad(true);
        });
    } catch (error) {
      ModalError(error);
    }
  }

  async function LoadData() {
    const code = searchParams.get("code");
    if (code !== null) {
      setCode(code);
      getDataRisco(code).then((data) => {});
    } else {
      setLoad(true);
    }
  }

  useEffect(() => {
    ValidateGetAction();
  }, [listRisco]);

  useEffect(() => {
    LoadData();
  }, []);
  useEffect(() => {
    if (probSelect.value !== undefined && impactoSelect.value !== undefined) {
      let nivelReturn = calcNivel(probSelect.value, impactoSelect.value);
      setNivel(nivelReturn);
    }
  }, [probSelect, impactoSelect]);

  async function handleValidate() {
    let temp = indexActionNull;

    temp.map((item, index) => {
      if (listRisco[item - 1].acao === true) {
        temp.splice(index, 1);
      }
    });

    listRisco.map(async (item, index) => {
      if (temp?.length > 0) {
        MySwal.fire({
          icon: "warning",
          title: "Oops...",
          text: `Os itens ${temp.join(
            ", "
          )} ainda nao foram cadrastradas ações preventivas ou de contigência. Deseja Continuar? \n\n `,
          backdrop: false,
          showConfirmButton: true,
          showCancelButton: false,
          showDenyButton: true,
          confirmButtonText: "Sim",

          denyButtonText: `Não`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            navigate(`/responsavel?code=${code}`);
          } else if (result.isDenied) {
          }
        });
      } else {
        navigate(`/responsavel?code=${code}`);
        console.log(indexActionNull);
      }
    });
  }

  async function handleAdd(e) {
    if (updateValue) {
      UpdateData(e);
      let items = [...listRisco];
      let item = {
        fase: e.faseSelect?.value,
        fase2: e.alocado?.value,
        probabilidade: e.probSelect.label,
        impacto: e.impactoSelect.label,
        nivel: nivel,
        describeRisco: dataEditor,
        item: e.itemContratacao,
        CodigoRiscoContratacao: items[currentItemUpdate].CodigoRiscoContratacao,
        acao: false,
      };

      items[currentItemUpdate] = item;

      setListRisco(items);

      setUpdateValue(false);
    } else {
      let CodigoRiscoContratacao = await submitData(e);
      if (listRisco) {
        setListRisco([
          ...listRisco,
          {
            fase: e.faseSelect?.value,
            fase2: e.alocado?.value,
            probabilidade: e.probSelect.label,
            impacto: e.impactoSelect.label,
            nivel: nivel,
            describeRisco: dataEditor,
            item: e.itemContratacao,
            CodigoRiscoContratacao: CodigoRiscoContratacao,
            acao: false,
          },
        ]);
      } else {
        setListRisco({
          fase: e.faseSelect?.value,
          fase2: e.alocado?.value,
          probabilidade: e.probSelect.label,
          impacto: e.impactoSelect.label,
          nivel: nivel,
          describeRisco: dataEditor,
          item: e.itemContratacao,
          CodigoRiscoContratacao: CodigoRiscoContratacao,
          acao: false,
        });
      }
    }
    setFaseSelect([]);
    setAlocado([]);
    setProbSelect([]);
    setImpactoSelect([]);
    setNivel("");
    setContentCategory("");
    setItemContratacao(``);

    setDataEditor("");
  }

  async function UpdateData(e) {
    try {
      const response = await api.put(
        "/api/v1.0/riscos-contratacoes",
        {
          Codigo: listRisco[currentItemUpdate].CodigoRiscoContratacao,
          Descricao: dataEditor,
          Fase: e.faseSelect?.value,
          Probabilidade: e.probSelect.value + 1,
          Impacto: e.impactoSelect.value + 1,
          Nivel: nivel,
          Alocado: e.alocado?.value,
          Item: e.itemContratacao ? e.itemContratacao : null,
        },
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );

      let timerInterval;
      MySwal.fire({
        title: "Risco Atualizado com sucesso!",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
        showCancelButton: false,
        showDenyButton: false,
        willClose: () => {
          clearInterval(timerInterval);
        },
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
        }
      });

      return response.data.Codigo;
    } catch (error) {
      ModalError(error);
    }
  }
  async function submitData(e) {
    try {
      const response = await api.post(
        "/api/v1.0/riscos-contratacoes",
        {
          CodigoGestaoRisco: code,
          Descricao: dataEditor,
          Fase: e.faseSelect?.value,
          Probabilidade: e.probSelect.value + 1,
          Impacto: e.impactoSelect.value + 1,
          Nivel: nivel,
          Alocado: e.alocado?.value,
          Item: e.itemContratacao ? e.itemContratacao : null,
        },
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );

      let timerInterval;
      MySwal.fire({
        title: "Risco salvo com sucesso!",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
        showCancelButton: false,
        showDenyButton: false,
        willClose: () => {
          clearInterval(timerInterval);
        },
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
        }
      });

      return response.data.Codigo;
    } catch (error) {
      ModalError(error);
    }
  }

  async function handleEditRisco(index) {
    setUpdateValue(true);
    const items = [...listRisco];
    let currentItem = items[index];
    setCurrentItemUpdate(index);

    setFaseSelect({
      value: `${currentItem.fase}`,
      label: `${currentItem.fase}`,
    });
    setAlocado({
      value: `${currentItem.fase2}`,
      label: `${currentItem.fase2}`,
    });
    setProbSelect({
      value: `${currentItem.probabilidade - 1}`,
      label: `${currentItem.probabilidade}`,
    });
    setImpactoSelect({
      value: `${currentItem.impacto - 1}`,
      label: `${currentItem.impacto}`,
    });
    setNivel(`${currentItem.nivel}`);
    setItemContratacao(`${currentItem.item}`);
    setContentCategory(`${currentItem.describeRisco}`);
    setDataEditor(`${currentItem.describeRisco}`);

    // setListRisco(items);
  }

  return (
    <>
      <Navbar titulo="Gestão de Risco" />

      <main className="main__estimativa_quantidades">
        <div className="container">
          <div className="row">
            <NavegacaoLateral ativo={2} code={code} />
            {load ? (
              <div className="col-sm-9 mt-4">
                <h2>Descrição do Risco </h2>

                <div className="row">
                  <Formik
                    initialValues={{
                      text: dataEditor,
                      faseSelect: faseSelect,
                      alocado: alocado,
                      probSelect: probSelect,
                      impactoSelect: impactoSelect,
                      itemContratacao: itemContratacao,
                    }}
                    // validationSchema={updateValue ? null : schema}
                    validationSchema={schema}
                    validateOnBlur={false}
                    onSubmit={(values, { resetForm }) => {
                      handleAdd(values);
                      resetForm();
                    }}
                  >
                    {({ setFieldValue, handleSubmit }) => (
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit(); // Chamada para o handleSubmit do Formik
                        }}
                      >
                        <Form.Group className=" editor  mb-5">
                          <ReactQuill
                            className="h-100"
                            name="text"
                            value={dataEditor}
                            onChange={(value) => {
                              setFieldValue("text", value);
                              setDataEditor(value);
                            }}
                          />
                          {/* <ErrorMessage component="p" name="text" /> */}
                        </Form.Group>
                        <div className="">
                          <div className="d-flex  flex-row ">
                            <Form.Group className="w-25 p-1 formGroup">
                              <Form.Label className="">
                                <span className="d-flex flex-row align-items-center">
                                  Relacionado à fase
                                </span>
                              </Form.Label>

                              <Select
                                name="faseSelect"
                                placeholder="Selecione"
                                value={faseSelect}
                                options={[
                                  {
                                    value: "Planejamento",
                                    label: "Planejamento",
                                  },
                                  { value: "Licitação", label: "Licitação" },
                                  { value: "Contrato", label: "Contrato" },
                                ]}
                                onChange={(selectedOptions) => {
                                  setFieldValue("faseSelect", selectedOptions);
                                  setFaseSelect(selectedOptions);
                                }}
                              />
                              <ErrorMessage component="p" name="faseSelect" />
                            </Form.Group>
                            <Form.Group className="mb-3 p-1 w-25 formGroup">
                              <Form.Label className="">
                                <span className="d-flex  flex-row align-items-center">
                                  Probabilidade
                                </span>
                              </Form.Label>

                              <Select
                                name="probSelect"
                                placeholder="Selecione"
                                value={probSelect}
                                options={[
                                  { value: 0, label: "1" },
                                  { value: 1, label: "2" },
                                  { value: 2, label: "3" },
                                  { value: 3, label: "4" },
                                  { value: 4, label: "5" },
                                ]}
                                onChange={(selectedOptions) => {
                                  setFieldValue("probSelect", selectedOptions);
                                  setProbSelect(selectedOptions);
                                }}
                              />
                              <ErrorMessage component="p" name="probSelect" />
                            </Form.Group>
                            <Form.Group className="mb-3 p-1 w-25 formGroup">
                              <Form.Label>
                                <span className="d-flex  flex-row align-items-center">
                                  Impacto
                                </span>
                              </Form.Label>

                              <Select
                                name="impactoSelect"
                                placeholder="Selecione"
                                value={impactoSelect}
                                options={[
                                  { value: 0, label: "1" },
                                  { value: 1, label: "2" },
                                  { value: 2, label: "3" },
                                  { value: 3, label: "4" },
                                  { value: 4, label: "5" },
                                ]}
                                onChange={(selectedOptions) => {
                                  setFieldValue(
                                    "impactoSelect",
                                    selectedOptions
                                  );
                                  setImpactoSelect(selectedOptions);
                                }}
                              />
                              <ErrorMessage
                                component="p"
                                name="impactoSelect"
                              />
                            </Form.Group>

                            <Form.Group className="mb-3 w-25 p-1 formGroup">
                              <Form.Label>
                                <span className="d-flex flex-row align-items-center">
                                  Nível
                                </span>
                              </Form.Label>
                              <Form.Control as="select">
                                <option disabled selected>
                                  {nivel}
                                </option>
                              </Form.Control>
                            </Form.Group>
                          </div>
                          <div className="d-flex flex-row w-100 ">
                            <Form.Group className="mb-3 p-1 w-50 formGroup">
                              <Form.Label>
                                <span className="d-flex  flex-row align-items-center">
                                  Alocado para:
                                </span>
                              </Form.Label>

                              <Select
                                name="alocado"
                                placeholder="Selecione a área"
                                value={alocado}
                                options={[
                                  { value: "Contratada", label: "Contratada" },
                                  { value: "Seguradora", label: "Seguradora" },
                                  {
                                    value: "Administração",
                                    label: "Administração",
                                  },
                                ]}
                                onChange={(selectedOptions) => {
                                  setFieldValue("alocado", selectedOptions);
                                  setAlocado(selectedOptions);
                                }}
                              />
                              <ErrorMessage component="p" name="alocado" />
                            </Form.Group>
                            <Form.Group className="mb-3 p-1 w-50 formGroup">
                              <Form.Label>
                                <span className="d-flex flex-row align-items-center">
                                  Item da contratação
                                </span>
                              </Form.Label>
                              <Field
                                type="text"
                                className="form-control"
                                name="itemContratacao"
                                value={itemContratacao}
                                onChange={(e) => {
                                  setFieldValue(
                                    "itemContratacao",
                                    e.target.value
                                  );
                                  setItemContratacao(e.target.value);
                                }}
                              />
                              <ErrorMessage
                                component="p"
                                name="itemContratacao"
                              />
                            </Form.Group>
                          </div>
                        </div>

                        <Button
                          className="btn btn-primary button "
                          type="submit"
                          onClick={
                            (handleSubmit,
                            () => {
                              setFieldValue("faseSelect", faseSelect);
                              setFieldValue("alocado", alocado);
                              setFieldValue("probSelect", probSelect);
                              setFieldValue("impactoSelect", impactoSelect);
                              setFieldValue("itemContratacao", itemContratacao);
                            })
                          }
                        >
                          {updateValue ? "Editar" : "Adicionar"}
                        </Button>
                      </Form>
                    )}
                  </Formik>

                  <div className="container">
                    <table className="table">
                      <tbody>
                        <tr className={styles.linha__tabbel_listagem}>
                          <th>Risco</th>
                          <th>Descrição do Risco</th>
                          <th>Relacionado a fase</th>
                          <th>Nível</th>
                          <th>Item da contratação</th>
                          <th>Ações</th>
                          <th></th>
                        </tr>

                        {listRisco?.length > 0 &&
                          listRisco?.map((i, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{removerTags(i.describeRisco)}</td>
                                <td>{i.fase}</td>
                                <td>{i.nivel}</td>
                                <td>{i.item}</td>
                                <td>
                                  <div className=" d-flex flex-row align-items-center justify-content-center ">
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id="button-tooltip-2">
                                          Editar a descrição do risco
                                        </Tooltip>
                                      }
                                    >
                                      {({ ref, ...triggerHandler }) => (
                                        <a
                                          variant="light"
                                          {...triggerHandler}
                                          className="d-inline-flex align-items-center p-1"
                                        >
                                          <i ref={ref}>
                                            <HiPencil
                                              type="button"
                                              name="teste"
                                              color="black"
                                              onClick={() =>
                                                handleEditRisco(index)
                                              }
                                            />
                                          </i>
                                        </a>
                                      )}
                                    </OverlayTrigger>

                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id="button-tooltip-2">
                                          Excluir o risco
                                        </Tooltip>
                                      }
                                    >
                                      {({ ref, ...triggerHandler }) => (
                                        <a
                                          variant="light"
                                          {...triggerHandler}
                                          className="d-inline-flex align-items-center p-1"
                                        >
                                          <i ref={ref}>
                                            <MdCancel
                                              type="button"
                                              style={{ color: "red" }}
                                              onClick={() => RemoveItem(index)}
                                            />
                                          </i>
                                        </a>
                                      )}
                                    </OverlayTrigger>

                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id="button-tooltip-2">
                                          Cadastrar/Visualizar Ação Preventiva
                                        </Tooltip>
                                      }
                                    >
                                      {({ ref, ...triggerHandler }) => (
                                        <a
                                          variant="light"
                                          {...triggerHandler}
                                          className="d-inline-flex align-items-center p-1"
                                        >
                                          <i ref={ref}>
                                            <BsExclamationLg
                                              color="black"
                                              type="button"
                                              onClick={() => {
                                                setCurrentAction({
                                                  CodigoRiscoContratacao:
                                                    i.CodigoRiscoContratacao,
                                                  tipo: 1,
                                                  tipoLabel: "Preventiva",
                                                });
                                                handleShow();
                                              }}
                                            />
                                          </i>
                                        </a>
                                      )}
                                    </OverlayTrigger>

                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id="button-tooltip-2">
                                          Cadastrar/Visualização Ação de
                                          Contingência
                                        </Tooltip>
                                      }
                                    >
                                      {({ ref, ...triggerHandler }) => (
                                        <a
                                          variant="light"
                                          {...triggerHandler}
                                          className="d-inline-flex align-items-center p-1"
                                        >
                                          <i ref={ref}>
                                            <BsFillExclamationTriangleFill
                                              type="button"
                                              style={{ color: "orange" }}
                                              onClick={() => {
                                                setCurrentAction({
                                                  CodigoRiscoContratacao:
                                                    i.CodigoRiscoContratacao,
                                                  tipo: 2,
                                                  tipoLabel: "Contingencia",
                                                });
                                                handleShow();
                                              }}
                                            />
                                          </i>
                                        </a>
                                      )}
                                    </OverlayTrigger>
                                    <div className="container_count_action">
                                      {i.countConti && (
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip id="button-tooltip-2">
                                              Quantidade Ações de Contingência
                                              Adicionadas
                                            </Tooltip>
                                          }
                                        >
                                          {({ ref, ...triggerHandler }) => (
                                            <a
                                              variant="light"
                                              {...triggerHandler}
                                              className="d-inline-flex align-items-center p-1"
                                            >
                                              <i ref={ref}>
                                                <div className="contadorAcao bg-danger">
                                                  {" "}
                                                  {i.countConti}
                                                </div>
                                              </i>
                                            </a>
                                          )}
                                        </OverlayTrigger>
                                      )}
                                      {i.countPrev && (
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip id="button-tooltip-2">
                                              Quantidade Ações Preventivas
                                              Adicionadas
                                            </Tooltip>
                                          }
                                        >
                                          {({ ref, ...triggerHandler }) => (
                                            <a
                                              variant="light"
                                              {...triggerHandler}
                                              className="d-inline-flex align-items-center p-1"
                                            >
                                              <i ref={ref}>
                                                <div className="contadorAcao bg-warning">
                                                  {" "}
                                                  {i.countPrev}
                                                </div>
                                              </i>
                                            </a>
                                          )}
                                        </OverlayTrigger>
                                      )}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>

                  <div>
                    <Button className="m-2" onClick={handleValidate}>
                      Salvar e Continuar
                    </Button>
                  </div>

                  {/* Modal Preencher etapa */}
                  <ModalAcao
                    listRisco={listRisco}
                    setListRisco={setListRisco}
                    show={show}
                    handleClose={handleClose}
                    CodigoRiscoContratacao={currentAction}
                  />
                  <Modal
                    show={showEtapa}
                    onHide={handleCloseEtapa}
                    animation={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Atenção</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                        É necessário preencher o campo da etapa de{" "}
                        <b>Descrição de Necessidade</b>
                      </p>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="primary" onClick={handleCloseEtapa}>
                        Entendi
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {/* Modal Visualização */}
                </div>
              </div>
            ) : (
              <RiscoContratacaoSkeleton />
            )}
          </div>
        </div>
      </main>

      {/* Rodape */}
      <Footer />
    </>
  );
};

export default RiscoContratacao;
