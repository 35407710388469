import { Link } from "react-router-dom";

import "./MenuFull.scss";

const MenuFull = (props) => {
  function CloseMenu() {
    props.setOpenSideNavigate(false);
  }
  return (
    <aside className="mt-4 aside_menu_full">
      <div className="container_menu">
        <div className="barra_superior">
          <h2>Etapas</h2>
          <button className="btn btn-sm" onClick={CloseMenu}>
            <i className="fa-solid fa-angles-left"></i>
          </button>
        </div>
        <div className="scroll_menu_risco ">
          <nav>
            <ul>
              <li className={props.ativo === 1 ? "ativo" : ""}>
                <span>1 </span>

                {props.code ? (
                  <Link to={`/informacoes-basicas?code=${props.code}`}>
                    Informações básicas
                  </Link>
                ) : (
                  <Link to={`/informacoes-basicas`}>Informações básicas</Link>
                )}
              </li>
              <li className={props.ativo === 2 ? "ativo" : ""}>
                <span>2 </span>
                {props.code ? (
                  <Link to={`/risco-da-contratacao?code=${props.code}`}>
                    Risco da contratação
                  </Link>
                ) : (
                  <Link to="/risco-da-contratacao">Risco da contratação</Link>
                )}
              </li>
              <li className={props.ativo === 3 ? "ativo" : ""}>
                <span>3 </span>
                {props.code ? (
                  <Link to={`/responsavel?code=${props.code}`}>
                    Responsável
                  </Link>
                ) : (
                  <Link to="/responsavel">Responsável</Link>
                )}
              </li>
              <li className={props.ativo === 4 ? "ativo" : ""}>
                <span>4 </span>
                {props.code ? (
                  <Link to={`/anexos?code=${props.code}`}>Anexos</Link>
                ) : (
                  <Link to="/anexos">Anexos</Link>
                )}
              </li>
              <li className={props.ativo === 5 ? "ativo" : ""}>
                <span>5 </span>
                {props.code ? (
                  <Link to={`/gerar-documentos?code=${props.code}`}>
                    Gerar Documento
                  </Link>
                ) : (
                  <Link to="/gerar-documentos">Gerar Documento</Link>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </aside>
  );
};

export default MenuFull;
