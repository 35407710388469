import styles from "./GerarDocumentos.scss";

import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { Link, useLocation, useNavigate } from "react-router-dom";

import Navbar from "../../../components/Navbar/Navbar";
import NavegacaoLateral from "../../../components/NavegacaoLateral/NavegacaoLateral";
import CKeditorEtp from "../../../components/CKeditor/CKeditorEtp";
import Footer from "../../../components/Footer/Footer";
import api from "../../../services/api";
import AuthContext from "../../../contexts/auth";
import { removerTags } from "../../../functions/removeHtml";
import { GerarDocumento } from "../../../components/skeletons/GerarDocumento";
import { ModalError } from "../../../components/ModalError/ModalError";

const GerarDocumentos = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showEtapa, setShowEtapa] = useState(false);
  const handleCloseEtapa = () => setShowEtapa(false);
  const handleShowEtapa = () => setShowEtapa(true);
  const [code, setCode] = useState("");
  const [dataEditor, setDataEditor] = useState(" ");
  const [contentCategory, setContentCategory] = useState(" ");
  const { state } = useContext(AuthContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [request, setRequest] = useState({});
  const [categories, setCategories] = useState([]);
  const [listRisco, setListRisco] = useState([]);
  const [infoBasica, setInfoBasica] = useState([]);
  const [acaoPreventiva, setAcaoPreventiva] = useState([]);
  const [acaoContigencia, setAcaoContigencia] = useState([]);
  const [responsavel, setResponsavel] = useState([]);

  const [loadBasic, setLoadBasic] = useState(false);
  const [loadAcao, setLoadAcao] = useState(false);
  const [loadRisco, setLoadRisco] = useState(false);
  const [loadResponsible, setLoadResponsible] = useState(false);

  async function getDataAcao(codigo) {
    // const valor = await CodigoRiscoContratacao;
    let dataResContigencia = [];
    let dataResPreventiva = [];
    try {
      let res = await api.get(
        `/api/v1.0/acoes?codigoRiscoContratacao=${codigo}`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );

      if (res.data) {
        const filteredData = res.data.filter(
          (item) => item.Status !== "Deletado"
        );

        // ==================================== Preventiva =============================
        const Preventiva = filteredData.filter(
          (item) => item.Tipo === "Preventiva"
        );
        Preventiva.forEach((item) => {
          dataResPreventiva.push({
            Codigo: item.Codigo,
            nome: item.Responsavel.Nome,
            cpf: item.Responsavel.CPF,
            cargo: item.Responsavel.Cargo,
            descricaoAcao: item.Descricao,
          });
        });

        const objetoPreventiva = {};
        objetoPreventiva[codigo] = dataResPreventiva;

        setAcaoPreventiva(...acaoPreventiva, objetoPreventiva);

        // ======================= Contigencia ===================================
        const Contigencia = filteredData.filter(
          (item) => item.Tipo === "Contingencia"
        );
        Contigencia.forEach((item) => {
          dataResContigencia.push({
            Codigo: item.Codigo,
            nome: item.Responsavel.Nome,
            cpf: item.Responsavel.CPF,
            cargo: item.Responsavel.Cargo,
            descricaoAcao: item.Descricao,
          });
        });

        const objetoContigencia = {};
        objetoContigencia[codigo] = dataResContigencia;

        setAcaoContigencia(...acaoContigencia, objetoContigencia);
      }
      setLoadAcao(true);
    } catch (error) {
      ModalError(error);
    }
  }

  async function getDataRisco(code) {
    try {
      api
        .get(`/api/v1.0/riscos-contratacoes?codigoGestaoRisco=${code}`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then((res) => {
          if (res.data) {
            let dataRes = res.data.map((item) => ({
              acao: getDataAcao(item.Codigo),
              fase: item.Fase,
              fase2: item.Alocado,
              probabilidade: item.Probabilidade,
              impacto: item.Impacto,
              nivel: item.Nivel,
              describeRisco: item.Descricao,
              item: item.Item,
              codigo: item.Codigo,
            }));

            setListRisco(dataRes);
          }

          setLoadRisco(true);
        });
    } catch (error) {
      ModalError(error);
    }
  }

  async function getResponsavel(code) {
    try {
      api
        .get(`/api/v1.0/responsaveis`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then((res) => {
          if (res.data) {
            // Aplicar filtro para excluir os itens com Status "Deletado"
            const filteredData = res.data.filter(
              (item) =>
                item.Status !== "Deletado" && item.CodigoGestaoRisco === code
            );

            const initialValues = filteredData.map(
              (curr, index) => ({
                code: curr.Codigo,
                nome: curr.Nome,
                cargo: curr.Cargo,
                cpf: curr.CPF,
                email: curr.Email,
              }),
              {}
            );

            setResponsavel(initialValues);
          }

          setLoadResponsible(true);
        });
    } catch (error) {
      ModalError(error);
    }
  }

  async function getDataInfoBasica(code) {
    try {
      api
        .get(`/api/v1.0/gestoes-riscos/${code}`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then((res) => {
          setInfoBasica({
            numDocumento: res.data?.NumeroDocumento,
            processInterno: res.data?.NumeroProcessoInterno,
          });

          setLoadBasic(true);
        });
    } catch (error) {
      ModalError(error);
    }
  }
  useEffect(() => {
    const code = searchParams.get("code");
    if (code !== null) {
      setCode(code);
      getDataRisco(code);
      getDataInfoBasica(code);
      getResponsavel(code);
    }
  }, []);

  return (
    <>
      <Navbar titulo="Gestão de Risco" />

      <main>
        <div className="container">
          <div className="row">
            <NavegacaoLateral ativo={5} code={code} />

            {loadBasic && loadResponsible && loadRisco ? (
              <div className="col-sm-9 mt-4">
                <h2>Gerar Documento</h2>

                <div>
                  <div className="rowContainer">
                    <div className="textContainer">
                      <div>
                        <p>Numero do Pedido:</p>
                        {infoBasica?.numDocumento}
                      </div>
                      <div>
                        <p>Órgão:</p>
                      </div>
                    </div>
                    <div className="textContainer">
                      <div>
                        <p>Numero do Processo Interno:</p>
                        {infoBasica?.processInterno}
                      </div>
                      <div>
                        <p>Setor Requisitante:</p>
                      </div>
                    </div>
                  </div>
                  {responsavel?.map((i) => {
                    return (
                      <div className="rowContainer">
                        <div className="textContainer">
                          <div>
                            <p>Responsável pela gestão de risco:</p>
                            {i.nome}
                          </div>
                          <div>
                            <p>Função:</p>
                            {i.cargo}
                          </div>
                        </div>
                        <div className="textContainer">
                          <div>
                            <p>CPF:</p>
                            {i.cpf}
                          </div>
                          <div>
                            <p>Email:</p>
                            {i.email}
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {listRisco?.length > 0 &&
                    listRisco?.map((i, index) => {
                      return (
                        <div key={index} className="rowContainer">
                          <div className="textContainer">
                            <div>
                              <p>Risco {index + 1}:</p>
                              {removerTags(i.describeRisco)}
                            </div>
                            <div>
                              <p>Relacionado à fase:</p>
                              {i.fase}
                            </div>
                            <div>
                              <p>Probabilidade:</p>
                              {i.probabilidade}
                            </div>

                            {acaoPreventiva[i.codigo]?.map((i, index) => {
                              return (
                                <div className="flex-column d-flex">
                                  <div>
                                    <p>Ação Preventiva {index + 1} :</p>
                                    {removerTags(i.descricaoAcao)}
                                  </div>
                                </div>
                              );
                            })}
                          </div>

                          <div className="textContainer">
                            <div>
                              <p>Impacto:</p> {i.impacto}
                            </div>
                            <div>
                              <p>Nível:</p>
                              {i.nivel}
                            </div>
                            <div>
                              <p>Relacionado ao item:</p>
                              {i.item}
                            </div>

                            {acaoContigencia[i.codigo]?.map((i, index) => {
                              return (
                                <div className="flex-column d-flex">
                                  <div>
                                    <p>Ação Contingência {index + 1}: </p>{" "}
                                    {removerTags(i.descricaoAcao)}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="container">
                  <table className="table">
                    <tbody>
                      <tr className={styles.linha__tabbel_listagem}>
                        <th>Risco</th>
                        <th>Descrição do Risco</th>
                        <th>Relacionado a fase</th>
                        <th>Nível</th>
                        <th>Item da contratação</th>

                        <th></th>
                      </tr>

                      {listRisco?.length > 0 &&
                        listRisco?.map((i, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{removerTags(i.describeRisco)}</td>
                              <td>{i.fase}</td>
                              <td>{i.nivel}</td>
                              <td>{i.item}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-end mt-5">
                  <div className=" d-flex flex-row">
                    <Button className="m-2">Gerar PDF</Button>

                    <Button className="m-2 btn_outline">Enviar</Button>
                  </div>
                </div>
              </div>
            ) : (
              <GerarDocumento />
            )}
          </div>
        </div>
      </main>

      {/* Rodape */}
      <Footer />
    </>
  );
};

export default GerarDocumentos;
