import Placeholder from "react-bootstrap/Placeholder";
export function NumProcessoSkeleton() {
  const index = [1, 2, 3, 4, 5];
  return (
    <div className="w-100 align-content-lg-start">
      <div className="w-100 ">
        <Placeholder animation="glow" style={{}}>
          <Placeholder
            style={{ width: "45%", height: "40px", marginTop: "30px" }}
            size="lg"
            bg="secondary"
          />
        </Placeholder>
        <Placeholder animation="glow" style={{ marginLeft: "20px" }}>
          <Placeholder
            style={{ width: "45%", height: "40px", marginTop: "30px" }}
            size="lg"
            bg="secondary"
          />
        </Placeholder>
      </div>

      {/* { (contentCategory|| newInput ) && <CKeditorEtp text={contentCategory} setDataEditor={setDataEditor}/>} */}

      <div className="row">
        <div className="col-md">
          <Placeholder animation="glow" style={{}}>
            <Placeholder
              style={{ width: "20%", height: "40px", marginTop: "30px" }}
              size="lg"
              bg="secondary"
            />
          </Placeholder>
        </div>
        {/* <GroupButtons handleSubmit={handleSubmit} handleShow={handleShow} handleShowEtapa={handleShowEtapa} /> */}
        {/* Modal Preencher etapa */}

        {/* Modal Visualização */}
        {/* <ModalPreViewEtapa 
   showPreview={show} 
   setShow={setShow}
   title="Descrição da Necessidade"
   body={dataEditor}
   /> */}

        {/* <ModalJustify showEtapa={showEtapa} handleCloseEtapa={handleCloseEtapa} handleJustify={handleJustify} /> */}
      </div>
    </div>
  );
}
