import axios from "axios";
import { useContext } from "react";
import AuthContext from "../contexts/auth";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;
