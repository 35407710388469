import styles from "./Responsavel.scss";
import Select from "react-select";

import Button from "react-bootstrap/Button";
// import Form from 'react-bootstrap/Form';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import Navbar from "../../../components/Navbar/Navbar";
import { MdCancel } from "react-icons/md";
import NavegacaoLateral from "../../../components/NavegacaoLateral/NavegacaoLateral";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../../components/Footer/Footer";
import ModalSelector from "../../../components/ModalSelector/ModalSelector";
import { useContext, useEffect, useState } from "react";

import { DataUserContext } from "../../../contexts/context";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { FormGroup } from "react-bootstrap";

import api from "../../../services/api";
import AuthContext from "../../../contexts/auth";
import { Key } from "@mui/icons-material";
import { FormResponsible } from "../../../functions/FormResponsible";
import { ResponsavelSkeleton } from "../../../components/skeletons/ResponsavelSkeleton";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { ModalError } from "../../../components/ModalError/ModalError";
const Responsavel = () => {
  const [load, setLoad] = useState(false);
  const { state } = useContext(AuthContext);

  const [request, setRequest] = useState({});
  const [code, setCode] = useState("");
  const location = useLocation();
  const MySwal = withReactContent(Swal);
  const searchParams = new URLSearchParams(location.search);
  const [valueRequester, setValueRequester] = useState([]);
  const [init, setInit] = useState({});
  const [fields, setFields] = useState([]);
  const navigate = useNavigate();

  async function getResponsavel(code) {
    try {
      api
        .get(`/api/v1.0/responsaveis?CodigoGestaoRisco=${code}`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then((res) => {
          if (res.data) {
            // Aplicar filtro para excluir os itens com Status "Deletado"
            const filteredData = res.data.filter(
              (item) =>
                item.Status !== "Deletado" && item.CodigoGestaoRisco === code
            );
            if (filteredData.length === 0) {
              setFields([FormResponsible(0, () => RemoveItem(0))]);
            }
            setValueRequester(filteredData);

            const initialValues = filteredData.reduce((acc, curr, index) => {
              return {
                ...acc,
                [`code-${index}`]: curr.Codigo,
                [`responsavel-${index}`]: curr.Nome,
                [`cargo-${index}`]: curr.Cargo,
                [`cpf-${index}`]: curr.CPF,
                [`email-${index}`]: curr.Email,
              };
            }, {});

            setInit(initialValues);
          }

          setLoad(true);
        });
    } catch (error) {
      ModalError(error);
    }
  }

  useEffect(() => {
    if (valueRequester?.length !== fields?.length) {
      valueRequester.map((item, index) => {
        setFields((prevFields) => [
          ...prevFields,
          FormResponsible(index, () => RemoveItem(index)),
        ]);
      });
    }
  }, [valueRequester]);
  async function LoadData() {
    const code = searchParams.get("code");
    if (code !== null) {
      setCode(code);
      getResponsavel(code);
    } else {
      setLoad(true);
    }
  }
  useEffect(() => {
    LoadData();
  }, []);

  async function submitData(e) {
    try {
      fields.map(async (i, index) => {
        if (e[`code-${index}`] === undefined) {
          // console.log({
          //   responsavel: e[`responsavel-${index}`],
          //   cpf: e[`cpf-${index}`],
          //   cargo: e[`cargo-${index}`],
          //   email: e[`email-${index}`],
          // });

          const response = await api.post(
            "/api/v1.0/responsaveis",
            {
              CodigoGestaoRisco: code,
              Nome: e[`responsavel-${index}`],
              Cargo: e[`cargo-${index}`],
              Email: e[`email-${index}`],
              CPF: e[`cpf-${index}`],
            },
            {
              headers: {
                Authorization: `Bearer ${state.token}`,
              },
            }
          );
        } else {
          const response = await api.put(
            "/api/v1.0/responsaveis",
            {
              Codigo: e[`code-${index}`],
              Nome: e[`responsavel-${index}`],
              Cargo: e[`cargo-${index}`],
              Email: e[`email-${index}`],
              CPF: e[`cpf-${index}`],
            },
            {
              headers: {
                Authorization: `Bearer ${state.token}`,
              },
            }
          );
        }
      });
      navigate(`/anexos?code=${code}`);
    } catch (error) {
      ModalError(error);
    }
  }
  const addField = () => {
    setFields([
      ...fields,
      FormResponsible(fields.length, () => RemoveItem(fields.length)),
    ]);
  };

  async function removeItemApi(code) {
    try {
      await api.delete(`/api/v1.0/responsaveis/${code}`, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
    } catch (error) {
      ModalError(error);
    }
  }

  function RemoveItem(key) {
    MySwal.fire({
      title: "Tem certeza?",
      text: "Você não será capaz de reverter isso!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, exclua!",
    }).then((result) => {
      if (result.isConfirmed) {
        setFields((prevFields) => {
          const updatedFields = prevFields.filter((item, index) => {
            if (item.key === `${key}`) {
              removeItemApi(document.getElementById(`code-${item.key}`).value);
            }

            return item.key !== `${key}`;
          });
          // console.log(prevFields);
          return updatedFields;
        });
        MySwal.fire("Excluído!", "O responsável foi deletado.", "success");
      }
    });
  }

  return (
    <>
      <Navbar titulo="Responsável" />

      <main>
        <div className="container">
          <div className="row">
            <NavegacaoLateral ativo={3} code={code} />

            <div className="col mt-4">
              <h2>Responsável</h2>

              <div className="">
                {load ? (
                  <>
                    <Formik
                      initialValues={init}
                      validationSchema={() => {
                        const schemaObject = {};

                        for (let index = 0; index < fields.length; index++) {
                          schemaObject[`responsavel-${index}`] =
                            Yup.string().required("Campo obrigatório");
                          schemaObject[`cpf-${index}`] =
                            Yup.string().required("Campo obrigatório");
                          schemaObject[`cargo-${index}`] =
                            Yup.string().required("Campo obrigatório");
                          schemaObject[`email-${index}`] = Yup.string()
                            .email("E-mail inválido")
                            .required("Campo obrigatório");
                        }

                        return Yup.object().shape(schemaObject);
                      }}
                      validateOnBlur={true}
                      validateOnChange={true}
                      onSubmit={submitData}
                    >
                      {({ setFieldValue }) => (
                        <Form className="mt-3 d-flex flex-xl-column">
                          <div>{fields.map((field, index) => field)}</div>
                          <div className=" d-flex justify-content-between">
                            <Button
                              className="btn btn-primary button"
                              onClick={addField}
                            >
                              Adicionar
                            </Button>
                            <Button
                              className="btn btn-primary button"
                              type="submit"
                            >
                              Salvar e Continuar
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </>
                ) : (
                  <ResponsavelSkeleton />
                )}
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* Rodape */}
      <Footer />
    </>
  );
};

export default Responsavel;
