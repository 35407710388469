import React, { useContext, useEffect, useState } from "react";
import styles from "./ModalAcao.scss";
import { HiPencil } from "react-icons/hi";
import { MdCancel } from "react-icons/md";
import { Button, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import CKeditorEtp from "../CKeditor/CKeditorEtp";
import { ErrorMessage, Field, Formik } from "formik";
import { removerTags } from "../../functions/removeHtml";
import AuthContext from "../../contexts/auth";
import api from "../../services/api";
import { DataUserContext } from "../../contexts/context";
import * as yup from "yup";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Importe o arquivo CSS do editor de texto
import { isValidCPF } from "../../functions/isValidCPF";

export function ModalAcao({
  show,
  handleClose,
  CodigoRiscoContratacao,
  listRisco,
  setListRisco,
}) {
  const { userData, setUserData } = useContext(DataUserContext);
  const [contentCategory, setContentCategory] = useState(" ");
  const [acaoPreventiva, setAcaoPreventiva] = useState([]);
  const [codigoTipo, setCodigoTipo] = useState(0);
  const [dataEditor, setDataEditor] = useState(" ");
  const [nome, setNome] = useState(" ");
  const [updateValue, setUpdateValue] = useState(false);
  const [cpf, setCpf] = useState(" ");
  const [cargo, setCargo] = useState(" ");
  const { state } = useContext(AuthContext);
  const [load, setLoad] = useState(false);
  const MySwal = withReactContent(Swal);
  const [currentItemUpdate, setCurrentItemUpdate] = useState("");
  const schema = yup.object().shape({
    nome: yup
      .string()
      .typeError("O Valor deve ser texto")
      .required("O responsável é obrigatório"),
    cpf: yup
      .string()
      .required("O CPF é obrigatório")
      .test("test-invalid-cpf", "CPF inválido", (cpf) => isValidCPF(`${cpf}`)),
    cargo: yup
      .string()
      .typeError("O Valor deve ser texto")
      .required("O cargo é obrigatório"),
  });

  async function getDataRisco(codigo) {
    const valor = await CodigoRiscoContratacao;
    let dataRes = [];
    try {
      const res = await api.get(
        `/api/v1.0/acoes?codigoRiscoContratacao=${codigo}`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (res.data) {
        const filteredData = res.data.filter(
          (item) => item.Status !== "Deletado"
        );

        filteredData.forEach((item) => {
          if (valor.tipoLabel === item.Tipo) {
            dataRes.push({
              Codigo: item.Codigo,
              nome: item.Responsavel.Nome,
              cpf: item.Responsavel.CPF,
              cargo: item.Responsavel.Cargo,
              descricaoAcao: item.Descricao,
            });
          }
        });
        setAcaoPreventiva(dataRes);
      }
      setLoad(true);
    } catch (error) {
      console.log("Error: " + error);
    }
  }

  async function LoadData() {
    const valor = await CodigoRiscoContratacao;
    setCodigoTipo(valor);
    if (valor) {
      getDataRisco(valor.CodigoRiscoContratacao);
    } else {
      setLoad(true);
    }
  }

  async function AddContainAction() {
    const valor = await CodigoRiscoContratacao;
    let tempList = listRisco;
    tempList.map((item) => {
      if (item.CodigoRiscoContratacao === valor.CodigoRiscoContratacao) {
        item.acao = true;
      }
      setListRisco(tempList);
    });
  }

  useEffect(() => {
    LoadData();
  }, [CodigoRiscoContratacao]);

  async function handleAdd(e) {
    if (updateValue) {
      UpdateData(e);
      let items = [...acaoPreventiva];
      let item = {
        nome: e.nome.trim(),
        cpf: e.cpf.trim(),
        cargo: e.cargo.trim(),
        descricaoAcao: dataEditor,
      };
      items[currentItemUpdate] = item;
      setAcaoPreventiva(items);

      setUpdateValue(false);
    } else {
      submitData(e);

      if (acaoPreventiva) {
        setAcaoPreventiva([
          ...acaoPreventiva,
          {
            nome: e.nome.trim(),
            cpf: e.cpf.trim(),
            cargo: e.cargo.trim(),
            descricaoAcao: dataEditor,
          },
        ]);
      } else {
        setAcaoPreventiva({
          nome: e.nome.trim(),
          cpf: e.cpf.trim(),
          cargo: e.cargo.trim(),
          descricaoAcao: dataEditor,
        });
      }
    }
    setNome("");
    setCpf("");
    setCargo("");
    setContentCategory("");
    setDataEditor("");
  }

  async function UpdateData(e) {
    const data = {
      Codigo: acaoPreventiva[currentItemUpdate].Codigo,
      Descricao: dataEditor,
      Responsavel: {
        CPF: e.cpf.trim(),
        Cargo: e.cargo.trim(),
        Nome: e.nome.trim(),
      },
    };

    try {
      const response = await api.put("/api/v1.0/acoes", data, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      AddContainAction();
    } catch (error) {
      console.log(error);
    }
  }

  async function submitData(e) {
    const valor = await CodigoRiscoContratacao;
    console.log(e);
    const data = {
      CodigoRiscoContratacao: valor.CodigoRiscoContratacao,
      Descricao: dataEditor,
      Responsavel: {
        CPF: e.cpf.trim(),
        Cargo: e.cargo.trim(),
        Nome: e.nome.trim(),
      },
      Tipo: valor.tipo,
    };

    try {
      const response = await api.post("/api/v1.0/acoes", data, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      AddContainAction();
    } catch (error) {
      console.log(error);
    }
  }
  function handleCloseModal() {
    handleClose();
    setAcaoPreventiva([]);
  }

  function RemoveItem(key) {
    MySwal.fire({
      title: "Tem certeza?",
      text: "Você não será capaz de reverter isso!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, exclua!",
    }).then((result) => {
      if (result.isConfirmed) {
        removeAcaoApi(acaoPreventiva[key].Codigo);
        const items = [...acaoPreventiva];
        items.splice(key, 1);
        setAcaoPreventiva(items);
        MySwal.fire("Excluído!", "Sua ação foi deletada.", "success");
      }
    });
  }
  async function removeAcaoApi(code) {
    try {
      await api.delete(`/api/v1.0/acoes/${code}`, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }
  async function handleEditRisco(index) {
    setUpdateValue(true);
    const items = [...acaoPreventiva];
    let currentItem = items[index];
    setCurrentItemUpdate(index);

    setNome(currentItem.nome);
    setCpf(currentItem.cpf);
    setCargo(currentItem.cargo);

    setContentCategory(`${currentItem.descricaoAcao}`);
    setDataEditor(`${currentItem.descricaoAcao}`);

    // setListRisco(items);
  }
  return (
    <>
      <Modal size="lg" show={show} onHide={() => handleCloseModal()}>
        <Modal.Header closeButton>
          <Modal.Title>
            {codigoTipo.tipo === 1 ? "Ação preventiva" : "Ação Contingência"}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>
            {" "}
            {codigoTipo.tipo === 1
              ? "Descrição da ação preventiva"
              : "Descrição da ação de contingência"}{" "}
          </h6>
          {load ? (
            <div className=" mt-4">
              {/* {
                <CKeditorEtp
                  text={contentCategory}
                  setDataEditor={setDataEditor}
                />
              } */}
              <div className="row"></div>

              <div>
                <Formik
                  initialValues={{
                    text: dataEditor,
                    nome: nome,
                    cpf: cpf,
                    cargo: cargo,
                  }}
                  validationSchema={schema}
                  validateOnBlur={false}
                  onSubmit={(values, { resetForm }) => {
                    handleAdd(values);
                    resetForm();
                  }}
                >
                  {({ setFieldValue, handleSubmit }) => (
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(); // Chamada para o handleSubmit do Formik
                      }}
                      className=" d-flex  flex-column justify-content-end"
                    >
                      <Form.Group className=" editor  mb-5">
                        <ReactQuill
                          className="h-100"
                          name="text"
                          value={dataEditor}
                          onChange={(value) => {
                            setFieldValue("text", value);
                            setDataEditor(value);
                          }}
                        />
                      </Form.Group>
                      <div className="d-flex flex-row ">
                        <Form.Group className="mb-3  w-50 p-1 formGroup">
                          <Form.Label className="">
                            <span className="d-flex flex-row align-items-center">
                              Responsável
                            </span>
                          </Form.Label>
                          <Field
                            type="text"
                            className="form-control"
                            name="nome"
                            value={nome}
                            onChange={(e) => {
                              setFieldValue("nome", e.target.value);
                              setNome(e.target.value);
                            }}
                          />
                          <ErrorMessage component="p" name="nome" />
                        </Form.Group>

                        <div className=" d-flex flex-row ">
                          <Form.Group className="mb-3 p-1 formGroup">
                            <Form.Label>
                              <span className="d-flex flex-row align-items-center">
                                CPF
                              </span>
                            </Form.Label>
                            <Field
                              type="text"
                              className="form-control"
                              name="cpf"
                              value={cpf}
                              onChange={(e) => {
                                setFieldValue("cpf", e.target.value);
                                setCpf(e.target.value);
                              }}
                            />
                            <ErrorMessage component="p" name="cpf" />
                          </Form.Group>

                          <Form.Group className="mb-3 p-1 formGroup">
                            <Form.Label>
                              <span className="d-flex flex-row align-items-center">
                                Cargo
                              </span>
                            </Form.Label>
                            <Field
                              type="text"
                              className="form-control"
                              name="cargo"
                              value={cargo}
                              onChange={(e) => {
                                setFieldValue("cargo", e.target.value);
                                setCargo(e.target.value);
                              }}
                            />
                            <ErrorMessage component="p" name="cargo" />
                          </Form.Group>
                        </div>
                      </div>
                      <div className=" d-flex justify-content-end">
                        <Button
                          className="btn btn-primary button "
                          type="submit"
                          onClick={
                            (handleSubmit,
                            () => {
                              setFieldValue("nome", nome);
                              setFieldValue("cpf", cpf);
                              setFieldValue("cargo", cargo);
                              setFieldValue("text", dataEditor);
                            })
                          }
                        >
                          {updateValue ? "Editar" : "Adicionar"}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>

              <div className="container">
                <table className="table">
                  <tbody>
                    <tr>
                      <th>
                        {" "}
                        {codigoTipo.tipo === 1
                          ? "Descrição da ação preventiva"
                          : "Descrição da ação de contingência"}{" "}
                      </th>
                      <th>Responsável</th>
                      <th>CPF</th>
                      <th>Cargo</th>
                      <th>Ações</th>
                      <th></th>
                    </tr>
                    {acaoPreventiva?.length > 0 &&
                      acaoPreventiva?.map((i, index) => {
                        return (
                          <tr key={index}>
                            <td>{removerTags(i.descricaoAcao)}</td>
                            <td>{i.nome}</td>
                            <td>{i.cpf}</td>
                            <td>{i.cargo}</td>
                            <td>
                              <div>
                                <HiPencil
                                  type="button"
                                  onClick={() => handleEditRisco(index)}
                                />
                                <MdCancel
                                  type="button"
                                  style={{ color: "red" }}
                                  onClick={() => RemoveItem(index)}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <></>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => handleCloseModal()}>
            Voltar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
