import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export function ModalError(message) {
  const MySwal = withReactContent(Swal);

  MySwal.fire({
    icon: "error",
    title: "Oops...",
    text: "A página atual não conseguiu carregar os dados;\n\n ",
    footer: `Erro: ${message.response?.status} `,
    backdrop: false,
    showCancelButton: false,
    showConfirmButton: false,
  });
  console.log(message);
  return <></>;
}
