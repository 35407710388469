import Placeholder from "react-bootstrap/Placeholder";
export function ResponsavelSkeleton() {
  const index = [1, 2, 3, 4, 5];
  return (
    <div className="w-100 align-content-lg-start">
      <div className="w-100 ">
        <Placeholder animation="glow" style={{}}>
          <Placeholder
            style={{ width: "33%", height: "40px", marginTop: "30px" }}
            size="lg"
            bg="secondary"
          />
        </Placeholder>
        <Placeholder animation="glow" style={{ marginLeft: "14px" }}>
          <Placeholder
            style={{ width: "33%", height: "40px", marginTop: "30px" }}
            size="lg"
            bg="secondary"
          />
        </Placeholder>
        <Placeholder animation="glow" style={{ marginLeft: "14px" }}>
          <Placeholder
            style={{ width: "14%", height: "40px", marginTop: "30px" }}
            size="lg"
            bg="secondary"
          />
        </Placeholder>
        <Placeholder animation="glow" style={{ marginLeft: "14px" }}>
          <Placeholder
            style={{ width: "14%", height: "40px", marginTop: "30px" }}
            size="lg"
            bg="secondary"
          />
        </Placeholder>
      </div>

      <div className="row">
        <div className="col-md ">
          <Placeholder animation="glow" style={{}}>
            <Placeholder
              style={{ width: "20%", height: "40px", marginTop: "30px" }}
              size="lg"
              bg="secondary"
            />
          </Placeholder>
          <Placeholder animation="glow" style={{ marginLeft: "58%" }}>
            <Placeholder
              style={{ width: "20%", height: "40px", marginTop: "30px" }}
              size="lg"
              bg="secondary"
            />
          </Placeholder>
        </div>
      </div>
    </div>
  );
}
