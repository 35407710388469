import React, {useContext, useState} from 'react';
import styles from "./login.module.scss";
import Logo from "./../../assets/logo/logo192.svg";
import LogoBranca from "./../../assets/logo/ico-logo-portal-branco.svg";
import api from '../../services/api';
import {useNavigate} from 'react-router-dom';
import AuthContext from '../../contexts/auth';


//libs validate forms
import { Formik, Form, Field, ErrorMessage} from "formik";
import * as yup from 'yup';
import SimpleNotification from '../../components/SimpleNotification/SimpleNotification';
const Login = () => {
   const navigate = useNavigate();
   const { dispatch } = useContext(AuthContext);




   const [loading, setLoading] = useState(false)
   const [errorSignIn, setErrorSignIn] = useState(false);
   // create yup schema object
   const schema = yup.object().shape({
       username: yup.string().required('O usuário é obrigatório'),
       password: yup.string().required('A senha é obrigatória'),
   })


   const onSubmit = async (values) => {


       setErrorSignIn(false)
  
       setLoading(true)
       try {
           const res = await api.post('/api/conta/login', values)
           
           dispatch({
               type: "LOGIN",
               payload: res.data.Detailed.AccessToken
           })
           navigate('/etp');
       } catch (err) {
           // console.log('err', err.response.data[""][0])
           // TODO: Mostrar toast nessa mensagem
           setErrorSignIn(true)
       } finally {
           setLoading(false)
       }
   }






   return (
       <div>
           <div className={styles.container__login}>
               <img src={Logo} alt="Logo"/>
               <div className={styles.container__loginTitulo}>
                   <h1>Entrar</h1>
               <div>
                   <SimpleNotification
                   active={errorSignIn}
                   changeActive={setErrorSignIn}
                   type="error"
                   icon={false}
                   >
                       Usuário ou senha incorretos!
                   </SimpleNotification>
                   </div>
                   </div>
                   <Formik
               initialValues={{ username: "", password: ""}}
               validationSchema={schema}
               validateOnBlur={false}
               onSubmit={(onSubmit)}
                       >
                   <Form className='form-formik'  >
                       <content className='input-div'>
                       <label>
                           <span>Nome de usuário (login)</span>
                       </label>
                       <Field
                           type="text"
                           name="username"
                           className="form-control"
                          
                       />
                      <ErrorMessage  component="p" name="username" />
                      </content>
                      <content className='input-div'>
                       <label>
                           <span>Senha</span>
                       </label>
                       <Field
                           type="password"
                           name="password"
                           className="form-control"
                         
                       />
                         <ErrorMessage component="p" name="password" />
                         </content>
                       <div>
                           <a href="/">Esqueci a senha</a>
                       </div>
                       <button className="btn btn-primary" type="submit" disabled={loading}>Entrar</button>
                   </Form>
                   
                   </Formik>
              
           </div>
           <div className={styles.imagem_direita}>
               <img className={styles.logoBranca_direita} src={LogoBranca} alt="Logo"/>
           </div>
       </div>
   );


}


export default Login;



