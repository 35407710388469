import { useContext, useEffect } from "react";
import AuthContext from "../../contexts/auth";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";

export function Auth() {
  const { state, dispatch } = useContext(AuthContext);
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  useEffect(() => {
    const tokenUrl = new URLSearchParams(window.location.search).get("token");

    if (tokenUrl === null && state.isAuthenticated === false) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: "A página atual não conseguiu carregar os dados;\n\n ",
        footer: "Erro: Token ausente ",
        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    } else if (tokenUrl !== null) {
      dispatch({
        type: "LOGIN",
        payload: {
          token: tokenUrl,
        },
      });
    }
  }, []);

  useEffect(() => {
    const auth = new URLSearchParams(window.location.search).get("auth");
    if (state.isAuthenticated === true) {
      if (auth === null) {
        navigate(`/gestao-risco?auth=true`);
      }
    }
  }, [state.isAuthenticated]);

  return <></>;
}
