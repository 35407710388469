import styles from "./ListagemInicial.scss";

import Navbar from "./../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import { useContext, useEffect, useReducer, useState } from "react";
import api from "../../../services/api";

import { useNavigate } from "react-router-dom";
import { DataUserContext } from "../../../contexts/context";
import { Field, Form, Formik } from "formik";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import authReducer, { authReducerInitialValue } from "../../../reducers/auth";
import AuthContext from "../../../contexts/auth";
import axios from "axios";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { ModalError } from "../../../components/ModalError/ModalError";

const moment = require("moment-timezone");

const ListagemInicial = () => {
  const MySwal = withReactContent(Swal);

  const [requestRisco, setRequestRisco] = useState([]);
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AuthContext);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  async function getData() {
    try {
      await axios
        .get(`${process.env.REACT_APP_BASEURL}api/v1.0/gestoes-riscos`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then((res) => {
          if (res.data) {
            setRequestRisco(res?.data);
          }
        });
    } catch (error) {
      ModalError(error);
    }
  }
  useEffect(() => {
    getData();
  }, []);

  async function handleClick() {
    navigate(`/informacoes-basicas`);
  }
  async function handleRequestEtp(event) {
    const startDateFormatada = moment(
      startDate,
      "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (zz)"
    ).format("YYYY-MM-DD");

    const endDateDateFormatada = moment(
      endDate,
      "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (zz)"
    ).format("YYYY-MM-DD");

    try {
      await axios
        .get(
          `${process.env.REACT_APP_BASEURL}api/v1.0/gestoes-riscos?inicio=${startDateFormatada}&final=${endDateDateFormatada}&numeroDocumento=${event.NGestaoRisco}&numeroProcessoInterno=${event.NumeroProcessoInterno}
          `,
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then((res) => {
          if (res.data) {
            setRequestRisco(res?.data);
          } else {
            setRequestRisco([]);
          }
        });
    } catch (error) {
      ModalError(error);
    }
  }
  async function handleEditRisco(codigo) {
    navigate(`/informacoes-basicas?code=${codigo}`);
  }
  async function handleViewRisco(codigo) {
    navigate(`/gerar-documentos?code=${codigo}`);
  }

  return (
    <>
      <Navbar
        titulo="Gestão de risco"
        subtitulo="Módulo de Planejamento"
        button={{ text: "+ Cadastrar novo" }}
        onClick={handleClick}
      />

      <main className="listagem-inicial">
        <Formik
          initialValues={{
            NGestaoRisco: "",
            data: "",
            NumeroProcessoInterno: "",
          }}
          onSubmit={handleRequestEtp}
        >
          <Form>
            <div className="container pt-3">
              <div className="d-flex flex-row gap-4  justify-content-center pb-3 pt-2">
                <div className="">
                  <Field
                    type="text"
                    name="NGestaoRisco"
                    placeholder="Nº da Gestão de Risco"
                    className="form-control text-red"
                  />
                </div>

                <div className="">
                  <Field
                    type="text"
                    name="NumeroProcessoInterno"
                    placeholder="Processo Interno"
                    className="form-control"
                  />
                </div>
                <div className="">
                  <DatePicker
                    className="DataPicker form-control"
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                      setDateRange(update);
                    }}
                    isClearable={true}
                    placeholderText="Data"
                  />
                </div>
                <div className="">
                  <button className="btn btn-primary btn-sm" type="submit">
                    Pesquisar
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
        <div className="container">
          <table className="table">
            <tbody>
              <tr className={styles.linha__tabbel_listagem}>
                <th>Nº da Gestão de Risco</th>
                <th>Número do processo interno</th>

                <th>Data do Cadastro</th>
                <th>Ações</th>
              </tr>

              {requestRisco?.map((Risco) => (
                <tr key={Risco.Codigo}>
                  <td>{Risco.NumeroDocumento}</td>
                  <td>{Risco.NumeroProcessoInterno}</td>

                  <td>{Risco.Criado.split(" ")[0]}</td>
                  <td>
                    <div>
                      <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => handleEditRisco(Risco.Codigo)}
                      >
                        Editar
                      </button>
                      <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => handleViewRisco(Risco.Codigo)}
                      >
                        Visualizar
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default ListagemInicial;
