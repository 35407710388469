import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

import AuthContext from '../contexts/auth';
import api from './api';

const WithAxios = ({ children }) => {
    const { state, dispatch } = useContext(AuthContext);
  
    

    api.interceptors.request.use( async config =>{
     
        const authState = await localStorage.getItem("token")
        
        if(!!authState){
           
            config.headers["Authorization"] =`Bearer ${authState}`
            
        }
        return config;
    },erro=>{
        return erro;
    }
    )



        api.interceptors.response.use(response => response, async error =>{
                const status =  error.response ? error.response.status :null;
                if(status === 415){
                    
                    dispatch({
                        type: 'LOGOUT',
                    })
                  
                }
                return (error);
            })
   

    return children
}

export default WithAxios