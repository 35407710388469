import React, { useContext, useReducer } from "react";
import {
  BrowserRouter,
  Navigate,
  redirect,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import ListagemInicial from "../pages/GestaoDeRisco/ListagemInicial/ListagemInicial";
import NumeroProcesso from "../pages/GestaoDeRisco/CadastroNumeroPrecesso/NumeroProcesso";
import Anexos from "../pages/GestaoDeRisco/CadastroAnexos/Anexos";
import AuthContext from "../contexts/auth";
import Login from "../pages/Login/Login";
import RiscoContratacao from "../pages/GestaoDeRisco/RiscoContratacao/RiscoContratacao";
import Responsavel from "../pages/GestaoDeRisco/Responsavel/Responsavel";
import GerarDocumentos from "../pages/GestaoDeRisco/GerarDocumentos/GerarDocumentos";
import { Auth } from "../pages/Auth";

const AppRoutes = () => {
  const { state } = useContext(AuthContext);
  const Private = ({ children }) => {
    if (!state.isAuthenticated) {
      return <Navigate to="/" />;
    }
    return children;
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Auth />} />

        <Route
          exact
          path="/gestao-risco"
          element={
            <Private>
              <ListagemInicial />
            </Private>
          }
        />
        <Route
          path="/informacoes-basicas"
          element={
            <Private>
              <NumeroProcesso />
            </Private>
          }
        />
        <Route
          path="/risco-da-contratacao"
          element={
            <Private>
              <RiscoContratacao />
            </Private>
          }
        />
        <Route
          path="/responsavel"
          element={
            <Private>
              <Responsavel />
            </Private>
          }
        />
        <Route
          path="/anexos"
          element={
            <Private>
              <Anexos />
            </Private>
          }
        />
        <Route
          path="/gerar-documentos"
          element={
            <Private>
              <GerarDocumentos />
            </Private>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
